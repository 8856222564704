import React from "react"
import { Link, graphql } from "gatsby"

import FullBio from "../components/full-bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
// import DisplayPanel from '../components/display-panel'
import SkillsBox from '../components/skills-box'
// import SkillsBoxMobile from '../components/skills-box-mobile'
// import { rhythm } from "../utils/typography"

import Box from '@material-ui/core/Box';
// import Container from '@material-ui/core/Container';
// import { ThemeProvider } from '@material-ui/styles';
// import { sizing } from '@material-ui/system';
// import Grid from '@material-ui/core/Grid';
// import Collapse from '@material-ui/core/Collapse';
import Grow from '@material-ui/core/Grow';

// import Fade from 'react-reveal/Fade';

// import mutedTheme from '../themes/muted-theme.js';
// import '../styles/site.css'
// import '../styles/site-phones.css'

// var me = require('../../content/assets/wavingtinyme.png');
//var me = require('../../content/assets/about-liz-img-cropped.png');
var me = require('../../content/assets/about-liz-img-update-1.png');
var me2 = require('../../content/assets/about-liz-img-no-color-1.png');
//var me3 = require('../../content/assets/headshot-1b-square.jpg');
var me3 = require('../../content/assets/headshot-1c-square.png');
var me4 = require('../../content/assets/headshot-4-cropped-2.jpg');
// var me4 = require('../../content/assets/headshot-2.jpg');
// var gears = require('../../content/assets/gears-light.png');
// var coding = require('../../content/assets/coding-symbol-light.png');
// var blossoms = require('../../content/assets/bare-blossoms.png');
var succulent = require('../../content/assets/succulent-1.png');
var floral = require('../../content/assets/floral-1.jpg');
var succulents = require('../../content/assets/succulents.png');
var plants = require('../../content/assets/plants-4.jpg');
var leaves = require('../../content/assets/leaves.jpg');

class AboutLiz extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="About Liz Nelson"
          description="A brief biography of writer, developer, and artist Liz Nelson, featuring her cats."
        />
        <Box
          display='flex'
          className='white-page'
        >
          <Box
            className='horizontal-container switch-container'
          //id='about-page-container'
          //id='horizontal-container-about'
          >

            <Box
              className='sub-content-block-vertical'
              //id='about-sub-content-block-vertical'
              bgcolor='primary.light'
              style={{
                backgroundImage: 'url(' + floral + ')',
                backgroundSize: 'cover',
                backgroundAttachment: 'fixed',
                //boxShadow: '0px -2px 2px rgba(34,34,34,0.6)',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                //background:'linear-gradient(to bottom, rgba(31,23,24,1) 0%, rgba(31,23,24,0.8) 60%, rgba(138,106,109,0) 100%)',
              }}
            >
              <Box
                className='block-header-gradient'
              >
                <Grow
                  in={true}
                  timeout={1000}
                >
                  <Box
                    color='primary.light'
                    //bgcolor='secondary.light'
                    flexWrap='flex-wrap'
                    id='about-title'
                  >
                    <h1
                      className='alt-medium-title'
                      id='about-header'
                    >
                      about Liz V. Nelson
                    </h1>
                  </Box>
                </Grow>
                <Box
                  //bgcolor='primary.main'
                  //justifyContent='center'
                  id='about-header-box'
                // style={{
                //  backgroundImage:`url(${me4})`,
                //  }}
                >
                  <img
                    src={me4}
                    id='liz-pic'
                  />
                </Box>
              </Box>

            </Box>
            <Box
              className='main-content-block-vertical'
              id='bio-padding'
            //bgcolor="primary.light"
            >
              <Box
                color='primary.main'
              //flexWrap='flex-wrap'
              //id='about-title'
              >
                <Grow
                  in={true}
                  timeout={1000}
                >
                  <h2
                    className='alt-big-title'
                    id='bio-header'
                  >
                    let's get a little <span style={{ color: 'var(--secondaryDark)' }}>personal</span>.
                  </h2>
                </Grow>
              </Box>

              <FullBio />
            </Box>



          </Box>
          <SkillsBox />
          <Box
            id='about-img'
            style={{
              backgroundImage: 'url(' + succulent + ')'
            }}>

          </Box>
        </Box>

      </Layout>
    )
  }
}

export default AboutLiz

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
