import React from "react"   
import { Link } from "gatsby"
import Box from '@material-ui/core/Box';

//var blank = require('../../content/assets/blank-tile.jpg');
var laptopCat = require('../../content/assets/cat-and-laptop-1.png');
var puddle = require('../../content/assets/liz-and-puddle-1.png');
var fox = require('../../content/assets/fox-sprite-2.png');

const FullBio = () => {
  return(
    <Box
     id='bio-box'
     >
     
     <br />
      <p>
        Liz is a web developer, game designer, professional graphic designer, and writer. She's usually engaged in an array of 
        creative programming projects and makes and manages digital marketing media for a variety of clients.
        <img
         src={laptopCat}
         className='bio-image-right'
         alt='A cat laying in front of a laptop'
         />
      </p>
      <p>  
        She studied English and Digital Media Arts & Engineering at LSU where she discovered her love of creative writing, programming, and visual art 
        and the enchanting ways these disciplines can overlap to creatively engineer exciting things. She has dreams of using these passions
        to bring positive change to society and is especially interested in promoting environmental sustainability and responsibility in coding and software development.
         <img
         src={fox}
         className='bio-image-left'
         alt='video game concept art of a fox'
         />   
      </p>
      <p>
        Her current passion projects include a 2D indie game about a little girl who fights to save a magical forest from an evil kingdom
        with the help of a powerful and cunning fox and 
        an aesthetic writing enviroment for authors in need of creative stimulation.
        She hopes you'll poke around on her <Link to='/blog'>blog</Link>.
        If you you need a website built or a graphic or logo designed, you can email her <a href = "mailto: elizabeth.v.nelson94@gmail.com">here</a>.
        <img
         src={puddle}
         className='bio-image-right'
         alt='drawing of liz with an umbrella'
         />   
      </p>
      <p>
        When she's not working, coding or writing, you can find her chilling in an armchair with a good sci-fi novel, 
        a cup of moderately strong coffee, and her two astonishingly similar tuxedo cats, Char and Boo.
      </p>
    </Box>
  )
}

  export default FullBio
