import React from "react"

import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

var starry = require('../../content/assets/stars.gif');
var succulent = require('../../content/assets/succulent-1.png');

export default class SkillsBox extends React.Component {
	render(){
		return(
			<Box
			 //bgcolor='primary.dark'
			 color='primary.main'
			 id='skills-box'
			 // style={{
    //         	backgroundImage: 'url('+succulent+')'
    //        	 }}
			>
				<Box
				 color='secondary.green'
				 className='skills-box-title'
				 >
					<h1
					 className='alt-big-title'
					  style={{
					 	margin: '0px',
					 }}
					>
					Technical Skills
				</h1>
				</Box>
				<Container
				 style={{
					marginTop: '50px',
					marginBottom: '25px',
					mixBlendMode: 'multiply',
					// paddingLeft: '100px',
					// paddingRight: '100px',
					}}
					>
					<Grid
					container spacing={4}
					direction="row"
					justify="center"

					>
						<Grid item>
						 &#10004;
						React.js
						</Grid>
						<Grid item>
						 &#10004;
						JavaScript
						</Grid>
						<Grid item>
						 &#10004;
						Node.js
						</Grid>
						<Grid item>
						 &#10004;
						Express.js
						</Grid>
						<Grid item>
						 &#10004;
						HTML5 & CSS3
						</Grid>
						<Grid item>
						 &#10004;
						GraphQL
						</Grid>
						<Grid item>
						 &#10004;
						Bootstrap
						</Grid>
						<Grid item>
						 &#10004;
						WordPress
						</Grid>
						<Grid item>
						 &#10004;
						Java
						</Grid>
						<Grid item>
						 &#10004;
						SQL
						</Grid>
						<Grid item>
						 &#10004;
						C#
						</Grid>
						<Grid item>
						 &#10004;
						ASP.NET Core
						</Grid>
						<Grid item>
						 &#10004;
						C++
						</Grid>
						<Grid item>
						 &#10004;
						SEO
						</Grid>
						<Grid item>
						 &#10004;
						Google Analytics
						</Grid>
						<Grid item>
						 &#10004;
						UX Design
						</Grid>
						<Grid item>
						 &#10004;
						Git
						</Grid>
					</Grid>
				</Container>
				<p>
				<hr class='solid-line-white' />
				</p>
				<Box
				 color='secondary.green'
				 className='skills-box-title'
				 >
				<h1
				 className='alt-big-title'
				 style={{
				  margin: '0px',
				 }}
				>
					software proficiencies
				</h1>
				</Box>
				<Container
				 style={{
					marginTop: '50px',
					marginBottom: '25px',
					// paddingLeft: '100px',
					// paddingRight: '100px',
				 }}
				>

					<Grid
					 container spacing={4}
					 direction="row"
					 justify="center"
					>
						<Grid item>
						 &#10004;
						Unity Game Engine
						</Grid>
						<Grid item>
						 &#10004;
						Adobe Photoshop
						</Grid>
						<Grid item>
						 &#10004;
						Adobe Illustrator
						</Grid>
						<Grid item>
						 &#10004;
						Adobe Indesign
						</Grid>
						<Grid item>
						 &#10004;
						Adobe XD
						</Grid>
						<Grid item>
						 &#10004;
						Autodesk Sketchbook
						</Grid>
					</Grid>
				</Container>
			</Box>
		)
	}
}